/* eslint-disable guard-for-in */
/* eslint-disable guard-for-in */
<template>
  <div class="wrap">
    <el-row style="margin-top: 20px;">
      <el-col :span="24" style="margin-bottom:20px">
        <!-- <p>电表查询</p> -->
        <el-tabs class="taps-card" v-model="form.type" @tab-click="searchAll">
          <el-tab-pane label="按年" name="3">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  @change="searchAll"
                  type="year"
                  value-format="yyyy-MM-dd"
                  placeholder="选择年">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId" @change="search" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '3')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="按月" name="2">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  type="month"
                  @change="searchAll"
                  value-format="yyyy-MM-dd"
                  placeholder="选择月">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId" @change="search" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '3')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="按天" name="1">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  @change="searchAll"
                  value-format="yyyy-MM-dd"
                  placeholder="选择天">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId" @change="search" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '3')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row  class="receiver-wrapper">
      <el-col :span="24" class="echart-wrapper" style="padding-top:20px" >
        <div class="receiver-title">{{dbDeviceName}} 电压统计</div>
        <ve-line :resizeable="true" :yAxis="{name:'(V)'}" :legend="legend" :grid="grid" height="300px" :data="chartData2"></ve-line>
      </el-col>
      <el-col :span="24" class="echart-wrapper">
        <div class="receiver-title">{{dbDeviceName}} 用电量统计</div>
        <ve-line :resizeable="true" :legend="legend" :yAxis="{name:'(KWH)'}" :grid="grid" height="300px" :data="chartData1"></ve-line>
      </el-col>
      <el-col :span="24" class="echart-wrapper">
        <div class="receiver-title">{{dbDeviceName}} 电流统计</div>
        <ve-line :resizeable="true" :legend="legend" :yAxis="{name:'(A)'}" :grid="grid" height="300px" :data="chartData"></ve-line>
      </el-col>
      <el-col :span="24" class="echart-wrapper">
        <div class="receiver-title">{{dbDeviceName}} 有功功率/无功功率</div>
        <ve-line :resizeable="true" :legend="legend" :yAxis="{name:'(KW)'}" :grid="grid" height="300px" :data="chartData4"></ve-line>
      </el-col>
      <!-- <el-col :span="24">
        <p>烟雾传感器查询</p>
        <el-tabs v-model="form.type" @tab-click="search1">
          <el-tab-pane label="按年查询" name="3">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  @change="searchAll"
                  type="year"
                  value-format="yyyy-MM-dd"
                  placeholder="选择年">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId1" @change="search1" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '4')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="按月查询" name="2">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  type="month"
                  @change="searchAll"
                  value-format="yyyy-MM-dd"
                  placeholder="选择月">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId1" @change="search1" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '4')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="按天查询" name="1">
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.value"
                  @change="searchAll"
                  value-format="yyyy-MM-dd"
                  placeholder="选择天">
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-select clearable v-model="form.deviceId1" @change="search1" placeholder="请选择设备">
                  <el-option :value="item.deviceId" :label="item.deviceName" v-for="(item, index) in DROPDOWNBOX.device.filter(item => item.deviceType === '4')" :key="`device_${index}`"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="12">
        <p class="title">{{ywDeviceName}} 烟雾统计</p>
        <p v-show="chartData3.rows.length > 0" class="unit">次</p>
        <ve-histogram :grid="grid" :data="chartData3"></ve-histogram>
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import mixin from '@/mixins/index'
import dictMixin from '@/mixins/dictMixin'


export default {
  mixins: [mixin, dictMixin],
  data() {
    this.grid = {
      show: true,
      // top: 50,
      // left: 10,
      backgroundColor: 'rgba(255,255,255,1)',
      borderColor: 'rgba(255,255,255,1)',
      shadowColor: "0px 2px 6px 0px rgba(0,0,0,0.1)",
    }
    this.legend = {
      top: 20,
    }
    return {
      form: {
        type: '3',
        value: new Date(),
        deviceId: '',
        deviceId1: '',
      },
      chartData: {
        columns: ['日期', 'A相电流', 'B相电流', 'C相电流'],
        rows: [],
      },
      chartData1: {
        columns: ['日期', '用电量'],
        rows: [],
      },
      chartData2: {
        columns: ['日期', 'A相电压', 'B相电压', 'C相电压'],
        rows: [],
      },
      chartData3: {
        columns: ['日期', '烟雾报警', '解除烟雾报警'],
        rows: [],
      },
      chartData4: {
        columns: ['日期', '有功功率', '无功功率'],
        rows: [],
      },
    }
  },
  computed: {
    ...mapState(['Language', 'DROPDOWNBOX']),
    ywDeviceName() {
      if (!this.form.deviceId1) {
        return ''
      }
      const str = this.DROPDOWNBOX.device.filter(item => item.deviceId === this.form.deviceId1)[0].deviceName
      return str
    },
    dbDeviceName() {
      if (!this.form.deviceId) {
        return ''
      }
      const str = this.DROPDOWNBOX.device.filter(item => item.deviceId === this.form.deviceId)[0].deviceName
      return str
    },
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.chartApi1, this.form).then((res) => {
        if (res.data.code === 0) {
          const arr = []
          res.data.rows[8].forEach((item, index) => {
            arr.push({})
            arr[index]['日期'] = item.value
            arr[index]['A相电流'] = item.qty
          })
          res.data.rows[9].forEach((item, index) => {
            arr[index]['B相电流'] = item.qty
          })
          res.data.rows[10].forEach((item, index) => {
            arr[index]['C相电流'] = item.qty
          })
          this.chartData.rows = arr
          // this.tableData = res.data.data.record
          // this.form.total = res.data.data.totalRecord
        }
      })
    },
    searchData1() {
      this.$http.post(apis.chartApi2, this.form).then((res) => {
        if (res.data.code === 0) {
          const arr = []
          res.data.rows.forEach((item, index) => {
            arr.push({})
            arr[index]['日期'] = item.value
            arr[index]['用电量'] = item.qty
          })
          this.chartData1.rows = arr
        }
      })
    },
    searchData2() {
      this.$http.post(apis.chartApi3, this.form).then((res) => {
        if (res.data.code === 0) {
          const arr = []
          res.data.rows[5].forEach((item, index) => {
            arr.push({})
            arr[index]['日期'] = item.value
            arr[index]['A相电压'] = item.qty
          })
          res.data.rows[6].forEach((item, index) => {
            arr[index]['B相电压'] = item.qty
          })
          res.data.rows[7].forEach((item, index) => {
            arr[index]['C相电压'] = item.qty
          })
          this.chartData2.rows = arr
          // this.tableData = res.data.data.record
          // this.form.total = res.data.data.totalRecord
        }
      })
    },
    searchData3() {
      this.$http.post(apis.chartApi4, {
        ...this.form,
        ...{
          deviceId: this.form.deviceId1,
        },
      }).then((res) => {
        if (res.data.code === 0) {
          const arr = []
          res.data.rows[111].forEach((item, index) => {
            arr.push({})
            arr[index]['日期'] = item.value
            arr[index]['烟雾报警'] = item.qty
          })
          res.data.rows[962].forEach((item, index) => {
            arr[index]['解除烟雾报警'] = item.qty
          })
          this.chartData3.rows = arr
        }
      })
    },
    searchData4() {
      this.$http.post(apis.selectPowerApi, this.form).then((res) => {
        if (res.data.code === 0) {
          const arr = []
          res.data.rows[2].forEach((item, index) => {
            arr.push({})
            arr[index]['日期'] = item.value
            arr[index]['有功功率'] = item.qty
          })
          res.data.rows[4].forEach((item, index) => {
            arr[index]['无功功率'] = item.qty
          })
          this.chartData4.rows = arr
        }
      })
    },
    search() {
      if (this.form.value) {
        this.searchData()
        this.searchData1()
        this.searchData2()
        this.searchData4()
      }
    },
    search1() {
      if (this.form.value) {
        this.searchData3()
      }
    },
    searchAll() {
      this.search()
      this.search1()
    },
  },
  mounted() {
    this.form.value = this.updateTimeFormat(this.form.value)
    this.getDevice().then(() => {
      this.form.deviceId = this.DROPDOWNBOX.device.filter(item => item.deviceType === '3')[0].deviceId
      this.form.deviceId1 = this.DROPDOWNBOX.device.filter(item => item.deviceType === '4')[0].deviceId
      // this.$nextTick(() => {
      //   this.searchAll()
      // })
    })
    this.$nextTick(() => {
        this.searchAll()
      })
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap{
  padding: 0 20px;
  overflow-x: auto;
  height: 100%;
  .receiver-wrapper {
    margin-bottom: 130px;
    border-radius:4px;
    border:1px solid #E4E9F1;
    padding: 0 15px;
  }
  .echart-wrapper {
    position: relative;
    .echart-unit {
      position: absolute;
      top: 48px;
      left: 18px;
      font-size: 14px;
    }
  }
  .receiver-title {
    // margin-bottom: 5px;
    text-align: center;
    color:rgba(36,45,65,1);
    font-size:14px;
  }
}
.title{
  width: 100%;
  text-align: center;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
