import { mapState, mapActions } from 'vuex'
import apis from '@/apis'

export default {
  computed: {
    ...mapState([
      'DROPDOWNBOX',
    ]),
  },
  methods: {
    ...mapActions({
      setDict: 'setDict',
    }),
    /**
     * @function 获取设备下拉
     */
    getDevice() {
      return new Promise((resolve, reject) => {
        this.$http.post(apis.deviceAllList).then((res) => {
          if (res.data.code === 0) {
            this.setDict({
              data: {
                ...this.DROPDOWNBOX,
                ...{
                  device: res.data.rows,
                },
              },
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /**
     * @function 获取区域下拉
     */
    // getArea() {
    //   this.$http.post(apis.areaAllList).then((res) => {
    //     if (res.data.code === 0) {
    //       this.setDict({
    //         data: {
    //           ...this.DROPDOWNBOX,
    //           ...{
    //             area: res.data.rows,
    //           },
    //         },
    //       })
    //     }
    //   })
    // },
  },
}
